
import { defineComponent, ref } from "vue";
//操作记录与Dealer一致
import { getOperRecordDetail } from "@/API/rv/rvDealer";
const columns = [
  {
    title: "操作角色",
    dataIndex: "userName",
    key: "userName"
  },
  {
    title: "动作",
    dataIndex: "actionName",
    key: "actionName"
  },
  {
    title: "内容",
    dataIndex: "content",
    key: "content",
    slots: {customRender: "content"}
  },
  {
    title: "操作时间",
    dataIndex: "createdAt",
    key: "createdAt"
  }
];
export default defineComponent({
  setup() {
    const data = ref([]);
    const current = ref<number>(1);
    const size = ref<number>(20);
    const total = ref<number>(0);
    const initData = () => {
      const params = {
        vinNo: sessionStorage.getItem("SiccoRecordVinNo"),
        dealerCode: sessionStorage.getItem("SiccoRecordDealerCode"),
        current: current.value,
        size: size.value
      };
      getOperRecordDetail({ params }).then(res => {
        data.value = res.content;
        total.value = res.totalElements;
      });
    };
    initData();
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };
    return {
      columns,
      data,
      pageChange,
      sizeChange,
      total,
      size
    };
  }
});
